module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":600,"linkImagesToOriginal":false,"withWebp":true,"quality":100,"showCaptions":false,"markdownCaptions":false,"sizeByPixelDensity":false,"backgroundColor":"white","tracedSVG":false,"loading":"lazy","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-preact/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Taylor Stauss","lang":"en","short_name":"Taylor Stauss","description":"Digital marketer, jamstack developer, and WordPress developer","start_url":"/","background_color":"#343a40","theme_color":"#343a40","display":"standalone","icon":"src/images/me-icon.png","icon_options":{"purpose":"any maskable"},"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"bc5cd4348aa3d10278ff1ce813b10a3e"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-analytics-without-segment/gatsby-browser.js'),
      options: {"plugins":[],"cdnUrl":"https://cdnjs.cloudflare.com/ajax/libs/analytics.js/2.9.1/analytics.min.js","services":{"Google Analytics":{"trackingId":"UA-175457938-1"}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]

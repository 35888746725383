import React from "react"
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"

export const wrapPageElement = ({ element }) => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey={process.env.GATSBY_RECAPTCHA_KEY}>
      {element}
    </GoogleReCaptchaProvider>
  )
}
